import React from "react"
import { css } from "emotion"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { spacing, typography } from "@avonova-noa/common"


const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return <p>{children}</p>
    }
  }
}


const wrapper = css`
  ${typography.body}
  background: #aff0e5;
  border-radius: 10px;
  padding: ${spacing.ms};
  margin: 0  0 ${spacing.ml};

  p {
    margin: 0;
  }

  // @media screen and (min-width: 768px) {
  //   margin-left: calc(-920px / 2 + (920px / 2) / 2);
  //   margin-right: calc(-920px / 2 + (920px / 2) / 2);
  //   margin-bottom: 2em;
  //   padding: 2em;
  // }
`

const Textbox = ({ content }: { content: any }) => {
  return (
    <aside className={wrapper}>
      {content.content.map((item) => {
        return documentToReactComponents(item, options)
      })}

      {/* {console.log("content", content)} */}
    </aside>
  )
}

export default Textbox
