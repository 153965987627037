import React from "react"
import { Link } from "gatsby"
import { css, cx } from "emotion"
import { Breakpoints, colors, spacing, typography } from "@avonova-noa/common"


export type NextPrevArticleProps = {
    article: {
        slug: string,
        title: string,
    },
    direction: "next" | "prev"
}

const wrapper = css`
    color: ${colors.green.green11};
    font-size: 28px;
    line-height: 40px; 
    display: block;
    text-decoration: none;
    padding: ${spacing.ml} ${spacing.xl};
    height: 100%;
    text-align: center;

    h2 {
        margin: 0;
        ${typography.h6};
        font-weight: normal;
        display: inline-block;
    }

    &:hover h2 {
        text-decoration: underline;
    }

    @media (${Breakpoints.mobile}) {
        margin-left: -20px;
        margin-right: -20px;
        padding: ${spacing.m} ${spacing.s};

        h2 {
            display: block;
        }
    }
`

const prev = css`
    background: ${colors.neutral.light1};
    margin-left: -20px;
    h2 {
        padding-left: 30px;
    }
`;

const next = css`
    background: ${colors.neutral.light2};
    margin-right: -20px;
    h2 {
        padding-right: 30px;
    }
`

const variations = {
    prev,
    next
}

export const NextPrevArticle: React.FC<NextPrevArticleProps> = ({ article, direction }) => {
    return (
        article &&
        <Link className={cx(wrapper, variations[direction])} to={`/${article.slug}`}>
            <h2 className={css`position:relative;`}>
                {direction === "prev" && <svg
                    className={css`position:absolute; left: 0; top: 50%; transform: translateY(-50%);`}
                    width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.01056 0.999817L1.00012 9.01025L9.01056 17.0207" stroke="#00211B" />
                </svg>}
                {article.title}
                {direction === "next" && <svg
                    className={css`position:absolute; right: 0; top: 50%; transform: translateY(-50%);`}
                    width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.01044 0.999817L9.02087 9.01025L1.01044 17.0207" stroke="#00211B" />
                </svg>}
            </h2>
        </Link>
    )
}