import { Breakpoints, colors, spacing, typography } from '@avonova-noa/common'
import { INLINES } from '@contentful/rich-text-types'
import { css, cx } from 'emotion'
import React, { useState } from 'react'

const footnoteOverlay = css`
  display:none; 
  
  button {
    display:none;
  }

  .footnote{
    font-family: Vollkorn;
    font-size: 14px;
    font-style: italic;
    line-height: 20px;
    width: 230px;
    right: -280px;
    margin-bottom: ${spacing.ms};
    &::before {
      border-bottom: 1px solid #333;
      color: #fc4b47;
      content: "" counter(footnotesNumber) "";
      counter-increment: footnotesNumber;
      display: block;
      font-style: normal;
      margin: 0 0 0.5em;
      padding: 0.5em 0 0;
    }
    @media (${Breakpoints.mobile}){
      display: block;
    }
  }

@media (${Breakpoints.tablet}){
    display: block;
    padding: ${spacing.ms};
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: ${spacing.xxs} 0 ${spacing.s};
    position: relative;
    button {
      display: inline-block;
      background: transparent;
      position: absolute;
      font-family:${typography.sansSerif};
      font-size: 12px;
      right: ${spacing.ms};
      top: ${spacing.ms};
      border: 0;
      color: ${colors.gray.dark1};
    }

    .footnote {
      width: 100%;
      &::before {
        color: ${colors.green.green11};
        padding-bottom: ${spacing.xxs};
        display: block;
      }
    }
  }
`
const footnoteWrapper = css`
  display: inline;
`
const hidden = css`
    visibility: hidden;
    position: absolute !important;
`
export const FootnoteOverlay = ({ item }: { item: any, value: string }) => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      {item.content.map((content: { value: string }) => (
        <span className="footnoteText" onClick={() => { setVisible(!visible) }} aria-describedby="footnote-label">
          {content.value}
        </span>
      ))
      }
      <div className={cx(footnoteOverlay, { [hidden]: !visible })}>
        <button onClick={() => { setVisible(false) }}>Lukk</button>
        <div className="footnote">{item.data.target.fields.content['nb-NO']}</div>
      </div>
    </>
  )
}
