import React, { useState, useEffect } from "react"
import { css } from "emotion"

function ReadingProgress() {
  const readingProgress = css`
    background: #f5f5f5;
    height: 8px;
    position: sticky;
    top: 0;
    z-index: 100;
    > div {
      background: #27ddbb;
      height: 100%;
      transition: 0.15s;
    }
  `

  const [percent, setPercent] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      const height = document.body.clientHeight - window.innerHeight

      // In progress
      // Now measures whole site height
      // Could maybe be 100 % when end of article is in view, and still
      // be 100 % when scroll to things below article (related articles etc.)

      const position =
        Math.round(document.body.getBoundingClientRect().top) * -1

      const percent = Math.round((position * 100) / height)

      setPercent(percent)
    }

    window.addEventListener("scroll", handleScroll)
  }, [])

  return (
    <div className={readingProgress} style={{ margin: "0 0 2em" }}>
      <div style={{ width: `${percent}%` }} />
    </div>
  )
}

export default ReadingProgress
