import React from "react"
import { css } from "emotion"
import { typography } from "@avonova-noa/common"

const wrap = css`
  @media (min-width: 620px) {
    position: relative;
    margin-left: calc(-920px / 2 + (920px / 2) / 2);
    margin-right: calc(-920px / 2 + (920px / 2) / 2);
    margin-bottom: 2em;
  }
`

const ImageAsset = ({
  description,
  url,
}: {
  url: string
  description: string
}) => {
  return (
    <div className={wrap}>
      <img src={url} />
      <div
        className={css`
          ${typography.caption}
          text-align: center;
          text-transform: none;
        `}
      >
        {description}
      </div>
    </div >
  )
}

export default ImageAsset
