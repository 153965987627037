import React from "react"
import { css, cx } from "emotion"
import { Breakpoints, typography } from "@avonova-noa/common"

const sticky = css`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
`

const fullWidthImage = css`
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 100vw;
  overflow: hidden;
  position: relative;
  right: 50%;

  /* @media (${Breakpoints.tablet}) { 
    height: calc(100vh - 60px);
  } */

  .gatsby-image-wrapper {
    height: auto;
    @media (${Breakpoints.tablet}) { height: 100%;}
  }
  `

const textbox = css`
  background: white;
  border-left: 12px solid #aff0e5;
  border-radius: 10px;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
 ${typography.intro}
  left: -20%;
  line-height: 32px;
  margin: 0 0 1em;
  padding: 2em;
  top: 50%;
`

const textboxWrapper = css`
  position: relative;
  left: 40%;
  width: 60%;
  @media (${Breakpoints.tablet}) {
    left: 0;
    width: auto;
  }
`

const image = css`
  align-items: center;
  display: flex;
  height: 100vh;
  padding: 16px;
  vertical-align: top;
  width: 50%;
  @media (${Breakpoints.tablet}) {
    margin: 0 -36px;
    padding: 0;
    width: calc(100% + 72px);
    img {
      width: 100%;
    }
   
  }
`

const wrapper = css`
  position: relative;
    margin-left: calc(-920px / 2 + (920px / 2) / 2);
    margin-right: calc(-920px / 2 + (920px / 2) / 2);
    margin-bottom: 32px;
    padding: 32px;
   @media (${Breakpoints.tablet}) {
    margin: 0;
  }
`
interface GalleryComponentProps {
  content: {
    content: {
      content: {
        value: string
      }[]
    }[]
  }
  imageUrl: string,
  viewType: string,
  title: string
}


const GalleryComponent = ({
  content,
  imageUrl,
  viewType,
  title
}: GalleryComponentProps) => {
  return (
    <div className={viewType === "animation" ? wrapper : css`position:relative;`}>
      <div className={viewType === "full-width" ? fullWidthImage : cx(sticky, image)}>
        <img src={imageUrl} />
        {viewType === "full-width" && title &&
          < p className={css` ${typography.caption} text-align: center; text-transform: none;`}>{title}</p>
        }
      </div>
      {
        viewType === "animation" &&
        <div className={textboxWrapper}>
          {content.content.map((item, index) => (
            <div key={"gallery_" + index} style={{ height: "100vh" }}>
              <div className={textbox}>{item.content[0].value}</div>
            </div>
          ))}
        </div>
      }
    </div >
  )
}

export default GalleryComponent
